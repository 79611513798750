

















































@import '../../utilities/config';
@import 'node_modules/bootstrap/scss/buttons';

.o-nav-loggedout {
  position: absolute;
  width: 100%;
  z-index: 100;
  padding-top: 30px;
  &__col-lang {
    text-align: right;
  }
  &__backlink {
    //border-color: ;
    //background-color: blue;
    color: gray('gray-750');
    background-color: gray('white');
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    padding: 7px 17px 7px 9px;

    &:hover,
    &:focus,
    &:active {
      background-color: gray('gray-100');
    }

    &::before {
      content: '';
      //color: gray('white');
      display: inline-block;
      margin-right: 8px;
      //height: 17px;
      height: 1em;
      width: 6px;
      background-image: url(../../assets/arrow_left_gray.svg);
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-top: 0.5px;
    }
  }
  &__langlink {
    color: gray('gray-700');
    text-decoration: none;
    font-weight: 500; //font-weight: 400;;
    cursor: pointer;
    display: inline-block;
    & + & {
      margin-left: 15px;
    }
    &--loggedout {
      display: none;
      @media screen and (min-width: breakpoint-min('xs')) {
        display: inline-block;
      }
    }
    &:hover,
    &:focus,
    &:active {
      color: darken(gray('gray-700'), 15%);
      text-decoration: underline;
    }
  }
}
