@import '../config';

@media screen {
  .tooltip {
    display: block !important;
    z-index: 900;

    $background-color: gray('gray-100');
    $border-color: gray('gray-400');
    $color: gray('black');
    $border-width: 2px;

    .tooltip-inner {
      padding: 8px 10px;
      background: $background-color;
      border: $border-width solid $border-color;
      color: $color;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 300;
      box-shadow: 0 2px 4px 0 rgba(gray('black'), 0.1), 0 4px 5px 0 rgba(gray('black'), 0.12),
      0 1px 10px 0 rgba(gray('black'), 0.14);
    }

    .tooltip-arrow {
      width: 12px;
      height: 12px;
      border: $border-width solid $border-color;
      position: absolute;
      margin: 6px;
      z-index: 1;
      transform: rotate(45deg);
      background-color: $background-color;
    }

    &[x-placement^='top'] {
      margin-bottom: 6px;

      .tooltip-arrow {
        border-width: $border-width;
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        bottom: -2px;
        margin-top: 0;
        margin-bottom: -2px;
      }

      &.popover {
        margin-bottom: 18px;
      }

      .popover-arrow {
        bottom: -8px;
        margin: 0;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 6px;

      .tooltip-arrow {
        border-width: $border-width;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        top: -2px;
        margin-top: -2px;
        margin-bottom: 0;
      }

      &.popover {
        margin-top: 18px;
      }

      .popover-arrow {
        top: -8px;
        margin: 0;
      }
    }

    &[x-placement^='right'] {
      margin-left: 6px;

      .tooltip-arrow {
        border-width: $border-width;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        left: -2px;
        margin-left: -2px;
        margin-right: 0;
      }

      &.popover {
        margin-left: 18px;
      }

      .popover-arrow {
        left: -8px;
        margin: 0;
      }
    }

    &[x-placement^='left'] {
      margin-right: 6px;

      .tooltip-arrow {
        border-width: $border-width;
        border-bottom-color: transparent !important;
        border-left-color: transparent !important;
        right: -2px;
        margin-left: 0;
        margin-right: -2px;
      }

      &.popover {
        margin-right: 18px;
      }

      .popover-arrow {
        right: -8px;
        margin: 0;
      }
    }

    &.popover {
      .popover-inner {
        background-color: gray('white');
        font-size: 14px;
        color: gray('black');
        padding: 24px;
        border-radius: 5px;
        border-color: gray('gray-200');
      }

      .popover-arrow {
        height: 18px;
        width: 18px;
        border-color: gray('gray-200');
        background-color: gray('white');
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
