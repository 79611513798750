















































































































































































































































































































































@import '../../utilities/config';
@import '../../utilities/sass/mixins';

.o-nav {
  &.o-nav--cs {
    .o-nav-top {
      top: 90px;
    }

    .o-nav-side {
      top: 150px;
    }
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
  .o-nav {
    &.o-nav--cs {
      .o-nav-top {
        top: 40px;
      }

      .o-nav-side {
        top: 100px;
      }
    }
  }
}

.o-nav-top {
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: gray('white');
  box-shadow: 0px 7px 20px 0px rgba(gray('black'), 0.12);
  z-index: 1000;
  display: flex;
  justify-content: space-between;

  &__left {
    display: flex;
    height: 100%;
    margin: 0 15px;
    align-items: center;
  }

  &__logo {
    margin-left: 15px;
  }

  &__right {
    display: flex;
    height: 100%;
    margin: 0 15px 0 0;
    align-items: center;

    &-lang {
      align-self: center;
      align-items: center;
      color: gray('gray-700');
      cursor: pointer;
      display: flex;
      flex: 0 0 auto;
      font-weight: 500; //font-weight: 400;;
      height: 40px;
      justify-content: center;
      margin-top: 2px;
      text-align: center;
      text-decoration: none;
      width: 40px;
    }

    &-select {
      align-self: center;
      display: none;
      flex: 0 1 auto;
      padding: 0 12px;

      @media screen and (min-width: breakpoint-min('sm')) {
        display: block;
      }
    }
  }
}

.o-nav-user {
  align-self: stretch;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  position: relative;

  &__btn {
    @include btn-default-properties;
    background: url(../../assets/user_dark_gray.svg) no-repeat center center;
    background-size: 16px auto;
    display: block;
    flex: 0 0 auto;
    height: 40px;
    width: 40px;
  }

  &__drop {
    background: color('white');
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.14);
    display: none;
    max-width: calc(100vw - 30px);
    position: absolute;
    right: 0;
    top: 100%;
  }

  &--toggled &__drop {
    display: block;
  }

  &__head {
    background: gray('gray-100');
    margin: 0;
    padding: 20px;

    &-name {
      color: gray('gray-700');
      display: block;
      font-size: 17px;
      font-weight: 500;
    }

    &-email {
      color: gray('gray-400');
      display: block;
      font-size: 14px;
      letter-spacing: 0.05px;
      width: 100%;
      //ellipsis
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__link {
    @include btn-default-properties;
    border: 1px solid #dde2e8;
    display: block;
    font-size: 14px;
    font-weight: 500; //font-weight: 400;;
    padding: 10px 20px;
    text-align: left;
    white-space: nowrap;
    width: 100%;

    &:hover {
      text-decoration: underline;
    }

    &--active {
      color: gray('gray-400');
    }

    & + & {
      margin-top: -1px;
    }
  }
}

.o-nav-side {
  height: calc(100% - 60px);
  width: 66px;
  position: fixed;
  top: 60px;
  background-color: gray('gray-700');
  transition: width 0.5s ease, transform 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(-100%);
  z-index: 900;
  display: flex;
  flex-direction: column;

  &__select {
    margin: 20px 15px 0;
  }

  &__new-project {
    margin: 20px 15px 0;

    &__btn {
      display: block;
      color: color('white');
      padding: 7px 0px 7px 36px;
      text-align: left;
      font-weight: 500;
      font-size: 15px;
      position: relative;
      text-decoration: none;
      background-color: theme-color('primary');
      border-radius: 18px;
      white-space: nowrap;
      width: 36px;
      overflow: hidden;
      transition: width 0.5s ease, background-color 0.5s ease, padding-left 1s ease;

      &::before {
        position: absolute;
        left: 18px;
        top: 18px;
        transform: translate(-50%, -50%);
        content: '';
        height: 13px;
        width: 13px;
        background-image: url(../../assets/add_white.svg);
        background-size: contain;
        background-repeat: no-repeat;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: darken(theme-color('primary'), 7.5%);
      }
    }
  }

  &__main {
    margin: 20px 15px 18px 0;

    &__btn {
      display: block;
      color: gray('gray-150');
      padding: 7px 0px 7px 47px;
      text-align: left;
      font-weight: 500;
      font-size: 15px;
      position: relative;
      text-decoration: none;
      white-space: nowrap;
      border-left: 4px solid transparent;
      border-radius: 0 18px 18px 0;
      width: 36px;
      overflow: hidden;
      //@media screen and (min-width: breakpoint-min('md')) {
      //@media screen and (min-width: breakpoint-min('lg')) {
      transition: color 0.5s ease, width 0.5s ease, background-color 0.5s ease, padding-left 1s ease, border-color 0.5s ease;
      //}
      &::before {
        position: absolute;
        left: 29px;
        top: 18px;
        transform: translate(-50%, -50%);
        content: '';
        height: 18px;
        width: 18px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &--dashboard {
        &::before {
          background-image: url(../../assets/dashboard_gray.svg);
        }

        &.o-nav-side__main__btn--active {
          &::before {
            background-image: url(../../assets/dashboard_green.svg);
          }
        }
      }

      &--projects {
        &::before {
          background-image: url(../../assets/home_gray.svg);
        }

        &.o-nav-side__main__btn--active {
          &::before {
            background-image: url(../../assets/home_green.svg);
          }
        }
      }

      &--invoices {
        &::before {
          background-image: url(../../assets/invoice_gray.svg);
        }

        &.o-nav-side__main__btn--active {
          &::before {
            background-image: url(../../assets/invoice_green.svg);
          }
        }
      }

      &--accreditations {
        &::before {
          background-image: url(../../assets/accreditation_gray.svg);
        }

        &.o-nav-side__main__btn--active {
          &::before {
            background-image: url(../../assets/accreditation_green.svg);
          }
        }
      }

      &--inspections {
        &::before {
          background-image: url(../../assets/check_circle_gray.svg);
        }

        &.o-nav-side__main__btn--active {
          &::before {
            background-image: url(../../assets/check_circle_green.svg);
          }
        }
      }

      &--active {
        border-left: 4px solid theme-color('primary');
        background-color: theme-color('light-green');
        color: theme-color('secondary');
      }

      &:hover {
        background-color: gray('gray-600');
        border-left: 4px solid gray('gray-400');

        &.o-nav-side__main__btn--active {
          border-left: 4px solid theme-color('primary');
          background-color: theme-color('light-green');
          color: theme-color('secondary');
        }
      }
    }
  }

  &__secondary {
    display: block;
    margin-top: auto;
    border-top: 1px solid gray('gray-600');
    padding: 25px 15px 25px 25px;

    // Required for perfect width when scrollbar is shown
    width: 100%;

    // Hotfix to prevent text compression (without preventing line return)
    // when the menu display only icon
    min-width: 220px;

    &__title {
      color: gray('gray-400');
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &__link {
      display: block;
      font-size: 15px;
      color: gray('gray-150');
      font-weight: 500; //font-weight: 400;;
      margin: 15px 0;
      text-decoration: none;

      &[target='_blank'] {
        &:after {
          content: '';
          display: inline-block;
          height: 0.875em;
          width: 0.875em;
          background-image: url(../../assets/external_link_white.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          margin-left: 0.5em;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--active,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__phone {
    border-top: 1px solid gray('gray-600');
    padding: 14px 0;

    &__link {
      display: block;
      color: gray('gray-150');
      padding: 7px 0px 7px 51px;
      text-align: left;
      font-weight: 500;
      font-size: 15px;
      position: relative;
      text-decoration: none;
      white-space: nowrap;
      width: 36px;
      overflow: hidden;
      transition: color 0.5s ease, width 0.5s ease, padding-left 1s ease;

      &::before {
        position: absolute;
        left: 33px;
        top: 18px;
        transform: translate(-50%, -50%);
        content: '';
        height: 18px;
        width: 18px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../../assets/telephone_gray.svg);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--mobile-toggled {
    transform: translateX(0);
    width: 240px;
    box-shadow: 7px 0px 20px 0px rgba(gray('black'), 0.25);

    .o-nav-side__new-project {
      &__btn {
        width: 100%;
        padding: 7px 18px 7px 44px;
      }
    }

    .o-nav-side__main {
      &__btn {
        width: 100%;
        padding: 7px 18px 7px 55px;
      }
    }

    .o-nav-side__phone {
      &__link {
        width: 225px;
        padding: 7px 18px 7px 59px;
      }
    }
  }

  @media screen and (min-width: breakpoint-min('sm')) {
    transform: translateX(0);

    &__select {
      display: none;
    }

    &__secondary {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity 0.5s ease, max-height 1s cubic-bezier(0, 1, 0.5, 1);
    }

    &--mobile-toggled {
      width: 240px;
      box-shadow: 7px 0px 20px 0px rgba(gray('black'), 0.25);

      .o-nav-side__new-project {
        &__btn {
          width: 100%;
          padding: 7px 18px 7px 44px;
        }
      }

      .o-nav-side__main {
        &__btn {
          width: 100%;
          padding: 7px 18px 7px 55px;
        }
      }

      .o-nav-side__secondary {
        max-height: 500px;
        opacity: 1;
        overflow: visible;
      }

      .o-nav-side__phone {
        &__link {
          width: 225px;
          padding: 7px 18px 7px 59px;
        }
      }
    }
  }

  @media screen and (min-width: breakpoint-min('lg')) {
    width: 240px;
    box-shadow: none;
    &__new-project {
      &__btn {
        width: 210px;
        padding: 7px 18px 7px 44px;
      }
    }
    &__main {
      &__btn {
        width: 225px;
        padding: 7px 18px 7px 55px;
      }
    }
    &__secondary {
      max-height: 500px;
      opacity: 1;
      overflow: visible;
      transition: opacity 0.5s ease, max-height 1s cubic-bezier(0, 1, 0.5, 1);
    }
    &__phone {
      &__link {
        width: 225px;
        padding: 7px 18px 7px 59px;
      }
    }

    &--desktop-toggled {
      width: 66px;

      .o-nav-side__new-project {
        &__btn {
          width: 36px;
          padding-left: 36px;
          padding-right: 0;
          overflow: hidden;
        }
      }

      .o-nav-side__main {
        &__btn {
          width: 36px;
          padding-left: 47px;
          padding-right: 0;
          overflow: hidden;
        }
      }

      .o-nav-side__secondary {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
      }

      .o-nav-side__phone {
        &__link {
          width: 36px;
          padding-left: 51px;
          padding-right: 0;
          overflow: hidden;
        }
      }
    }
  }
}
