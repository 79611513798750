
















































@import '../../utilities/config';
.o-csr {
  background-color: theme-color('primary');
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  text-align: center;
  color: gray('white');
  font-size: 14px;
  overflow: hidden;

  &__row {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__col {
    &-sep {
      margin: 0 0.5em;
      display: none;
    }
    &-bold {
      font-weight: 400;
    }
  }

  a,
  &__link {
    background: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    padding: 0;
    user-select: none;
    white-space: normal;

    color: gray('white');
    text-decoration: underline;

    &:hover {
      color: gray('white');
    }
  }
}
@media screen and (min-width: breakpoint-min('sm')) {
  .o-csr {
    &__row {
      height: 40px;
      flex-direction: row;
    }
    &__col {
      &-sep {
        display: block;
      }
    }
  }
}
