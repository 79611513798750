










































































.a-select {
  &__select {
    position: relative;
    display: block;

    select.form-control {
      cursor: pointer;
      appearance: none;

      &::placeholder {
        color: gray('gray-150');
        font-weight: 300;
      }
    }

    &::after {
      background: white;
      border-radius: 0 6px 6px 0;
      content: '';
      width: 43px;
      height: calc(100% - 4px);
      position: absolute;
      right: 2px;
      top: 2px;
      background-image: url(../../assets/chevron_down_dark_green.svg);
      background-size: 15px auto;
      background-repeat: no-repeat;
      background-position: center center;
      pointer-events: none;
    }
  }
}

.invalid-feedback.html5-validation {
  display: none;
}

.was-validated .invalid-feedback.html5-validation {
  display: block;
}
