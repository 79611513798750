@import '../config';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/utilities/flex';
@import 'node_modules/bootstrap/scss/utilities/display';

$nav-full-width: 240px;

.container-fluid {
  &.app-logged-in {
    transition: padding-left 0.5s ease;
    margin-top: 81px;

    &.app-logged-in--cs {
      margin-top: 151px;
    }
  }
}

@media screen {
}

@media screen and (min-width: breakpoint-min('xs')) {
}

@media screen and (min-width: breakpoint-min('sm')) {
  .container-fluid {
    &.app-logged-in {
      padding-left: 81px;

      &.app-logged-in--cs {
        margin-top: 121px;
      }
    }
  }
}

@media screen and (min-width: breakpoint-min('lg')) {
  .container-fluid {
    &.app-logged-in {
      padding-left: 255px;

      .o-nav--toggled + & {
        padding-left: 81px;

        .form__header {
          width: calc(100% - 66px);
        }
      }
    }
  }
}

@media screen and (min-width: breakpoint-min('xl')) {
}
