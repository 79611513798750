@import '../config';
@import 'node_modules/bootstrap/scss/reboot';

@font-face {
  font-family: 'Rubik';
  src: url('../../assets/fonts/Rubik/Rubik-Italic.woff2') format('woff2'),
    url('../../assets/fonts/Rubik/Rubik-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../assets/fonts/Rubik/Rubik-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Rubik/Rubik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../assets/fonts/Rubik/Rubik-BlackItalic.woff2') format('woff2'),
    url('../../assets/fonts/Rubik/Rubik-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../assets/fonts/Rubik/Rubik-Black.woff2') format('woff2'),
    url('../../assets/fonts/Rubik/Rubik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../assets/fonts/Rubik/Rubik-LightItalic.woff2') format('woff2'),
    url('../../assets/fonts/Rubik/Rubik-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../assets/fonts/Rubik/Rubik-BoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/Rubik/Rubik-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../assets/fonts/Rubik/Rubik-MediumItalic.woff2') format('woff2'),
    url('../../assets/fonts/Rubik/Rubik-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../assets/fonts/Rubik/Rubik-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Rubik/Rubik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../assets/fonts/Rubik/Rubik-Light.woff2') format('woff2'),
    url('../../assets/fonts/Rubik/Rubik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../assets/fonts/Rubik/Rubik-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Rubik/Rubik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@media screen {
  a:focus,
  button:focus {
    outline-color: theme-color('primary');
  }

  h1 {
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
  }
  h2 {
    font-size: 25px;
    font-weight: 300;
  }
  strong {
    //color: gray('gray-750');
    //font-weight: 500; //font-weight: 400;;
    font-weight: 500;
  }
  em {
    font-style: normal;
    background-color: theme-color('primary');
    color: gray('white');
    padding: 0.15em 0.3em;
  }
  blockquote {
    background-color: gray('gray-100');
    padding: 20px 30px;
  }
  ul,
  ol {
    margin-top: 1rem;
    list-style: square;
  }
  li {
    margin-bottom: 0.5rem;
  }
  p {
    //margin-bottom: 0.5rem;
    margin-bottom: 1rem;
  }
  .text-warning {
    font-weight: 500; //font-weight: 400;;
    color: theme-color('warning');
  }
  .text-primary {
    font-weight: 500; //font-weight: 400;;
    color: theme-color('primary');
  }
  .text-danger {
    font-weight: 500; //font-weight: 400;;
    color: theme-color('danger');
  }
}

@media screen and (min-width: breakpoint-min('xs')) {
  h1 {
    //color: theme-color('secondary');
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
  h1 {
    //color: theme-color('success');
  }
}

@media screen and (min-width: breakpoint-min('md')) {
  h1 {
    //color: theme-color('warning');
  }
}

@media screen and (min-width: breakpoint-min('lg')) {
  h1 {
    //color: theme-color('danger');
  }
}

@media screen and (min-width: breakpoint-min('xl')) {
  h1 {
    //color: theme-color('warning');
  }
}
