












.tooltip-icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  background-image: url(../../assets/info_solid.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
